import React from 'react';
import Layout from '../../components/Layout/Layout';
import Seo from '../../components/Seo/Seo';
import DmHero from '../../components/DigitalMarketing/DmHero/DmHero';
import DmIntro from '../../components/DigitalMarketing/DmIntro/DmIntro';
import DmSem from '../../components/DigitalMarketing/DmSem/DmSem';
import DmTwo from '../../components/DigitalMarketing/DmTwo/DmTwo';
import LetsChat from '../../components/Home/LetsChat/LetsChat';
import DmServices from '../../components/DigitalMarketing/DmServices/DmServices';

const DmPage = () => (
  <Layout>
    <Seo title="Digital Marketing" />
    <DmHero />
    <DmIntro />
    <DmSem />
    <DmServices />
    <LetsChat />
  </Layout>
)


export default DmPage;
