import { useStaticQuery, graphql } from "gatsby"

export const useDmServicesQuery = () => {
  const data = useStaticQuery(graphql`
    query DmServiceQuery {
        wpPage(databaseId: {eq: 193}) {
            id
            digitalMarketing {
              dmServices
              }
            }
          }
  `)

  return data
}
