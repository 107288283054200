import { useStaticQuery, graphql } from "gatsby"

export const useDmHeroQuery = () => {
  const data = useStaticQuery(graphql`
    query DmHeroQuery {
      wpPage(databaseId: {eq: 193}) {
        id
        digitalMarketing {
          hero {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          heroMask {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return data
}
