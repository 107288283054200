import { useDmHeroQuery } from "../../../hooks/useDmHeroQuery"

const DmHeroMask = () => {
  
  const {
    wpPage: { digitalMarketing: data },
  } = useDmHeroQuery()

  const imageMask = data.heroMask.localFile.publicURL


  return (
    imageMask
  )
}

export default DmHeroMask
