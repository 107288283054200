import { useStaticQuery, graphql } from "gatsby"

export const useDmIntroQuery = () => {
  const data = useStaticQuery(graphql`
    query DmIntroQuery {
        wpPage(databaseId: {eq: 193}) {
            id
            digitalMarketing {
              introText
              introImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
    }
  `)

  return data
}
