import { useStaticQuery, graphql } from "gatsby"

export const useDmSemQuery = () => {
  const data = useStaticQuery(graphql`
    query DmSemQuery {
        wpPage(databaseId: {eq: 193}) {
            id
            digitalMarketing {
              semText
              semImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
    }
  `)

  return data
}
