import React from "react"
import { Wrapper } from "./DmServices.styles"
import { useDmServicesQuery } from "../../../hooks/useDmServicesQuery"

const DmServices = () => {

  const {
    wpPage: { digitalMarketing: data },
  } = useDmServicesQuery()
  const dmServices = data.dmServices
  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
        <div className="dm-services-text" dangerouslySetInnerHTML={{ __html: dmServices }} />
        </div>
      </div>
    </Wrapper>
  )
}

export default DmServices
